import React from 'react'
import { Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import Navabr from "../Components/Navbar/Navbar";
import Footer from './Footer/Footer';
import About from './About/About';
import ContactUs from './Contact/ContactUs';
import HireUs from './HireUs/HireUs';
import OurWork from './OurWork/OurWork';
import TheLabourParty from './InnerPage/TheLabourParty';
import LivingStandard from './InnerPage/LivingStandard';
import HedinghamCastle from "./InnerPage/HedinghamCastle";
import LifeupHealthCoaching from './InnerPage/LifeupHealth';
import TheCloudMantra from './InnerPage/TheCloudMantra';
import LifeupCorporateWellness from "./InnerPage/LifeupCorporateWellness";
import JosepAllenBoone from "./InnerPage/JosephAllenBoone";
import ReformAlliance from "./InnerPage/ReformAlliance";
import HarryBoone from "./InnerPage/HarryBoone";
import CrisisTextLine from "./InnerPage/CrisisTextLine";
import PrivacyPolicy from './PrivacyPolicy';
import CommunityCatalyst from "./InnerPage/CommunityCatalyst";
import MarchForOurLives from "./InnerPage/MarchForOurLives";
import NowNyc from './InnerPage/NowNyc';
import AccountableUs from "./InnerPage/AccountableUs";
import EconomicSecurityProject from "./InnerPage/EconomicSecurityProject";
import CivilRights from "./InnerPage/CivilRights";
const AllRoutes = () => {
    return (
        <>
            <Navabr />
            <Routes>
                <Route index element={<Home />} />
                <Route path="hireus" element={<HireUs />} />
                <Route path="Ourwork" element={<OurWork />} />
                <Route path="about" element={<About />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="hire-us" element={<HireUs />} />
                <Route path="our-work" element={<OurWork />} />
                <Route path="the-labour-party" element={<TheLabourParty />} />
                <Route path="living-standard" element={<LivingStandard />} />
                <Route path="privacy-policies" element={<PrivacyPolicy />} />
                <Route path="lifeup-health-coaching" element={<LifeupHealthCoaching />} />
                <Route path="hedingham-castle" element={<HedinghamCastle />} />
                <Route path="lifeup-corporate-wellness" element={<LifeupCorporateWellness />} />
                <Route path="joseph-allen-boone" element={<JosepAllenBoone />} />
                <Route path="the-cloud-mantra" element={<TheCloudMantra />} />
                <Route path="reform-alliance" element={<ReformAlliance />} />
                <Route path="Crisis-Text-Line" element={<CrisisTextLine />} />
                <Route path="community-catalyst" element={<CommunityCatalyst />} />
                <Route path="march-for-our-lives" element={<MarchForOurLives />} />
                <Route path="now-nyc" element={<NowNyc />} />
                <Route path="harryboone" element={<HarryBoone />} />
                <Route path="accountable-us" element={<AccountableUs />} />
                <Route path="economic-security-project" element={<EconomicSecurityProject />} />
                <Route path="the-leadership-conference-on-civil-human-rights" element={<CivilRights />} />
            </Routes>
            <Footer />
        </>
    )
}

export default AllRoutes
