import React from 'react';
import { Helmet } from "react-helmet";
import hedinghamCastleBanner from "../../Assests/img/HedinghamCastle/hedingham-castle-banner.webp";
import headinghamTitle from "../../Assests/img/HedinghamCastle/headingham rotate-form.webp";
import hedighamLaptopScaled from "../../Assests/img/HedinghamCastle/hedigham-laptop-scaled.webp";
import livingStandardFullwidth from "../../Assests/img/living-standard-fullwidth-color.webp";
import hedinghamMobileScaled from "../../Assests/img/HedinghamCastle/hedingham-mobile-scaled.webp";
import hedinghamHomepage from "../../Assests/img/HedinghamCastle/hedighma-fullwidth.webp";
import HedinghamImg from "../../Assests/img/HedinghamCastle/HedinghamImg.webp";
import Hedingham5 from "../../Assests/img/HedinghamCastle/Hedingham5.webp";
import Hedingham2 from "../../Assests/img/HedinghamCastle/Hedingham2.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const HedinghamCastle = () => {
    return (
        <>
            <Helmet>
                <title>Heritage Site Web Design | Custom Development for Historic Sites | LeapWide</title>
                <meta name="description" content="See how Leapwide transformed the digital presence of Hedingham Castle with a custom website design, offering a modern touch to historic heritage." />
            </Helmet>
            <div className='top-spacing container-fluid p-0' id='hedingham-castle'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={hedinghamCastleBanner} alt='hedinghamCastleBanner' title='hedinghamCastleBanner'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h3 class="border-head">
                                    <span>Hedingham Castle</span>
                                </h3>
                                <p className='para-txt'>Hedingham Castle is an iconic medieval period Norman keep turned hotel. Nestled in the midst of Scotland’s beautiful landscapes, it’s surrounded by gardens and acres of manicured lawns. Hedingham needed us to build a visually website showcasing the castle’s historic essence.</p>
                            </div>
                        </div>
                        {/* <div className='col-md-1'>
                            <div class="arrow"><a href="#featured-products"></a></div>
                        </div> */}
                    </div>
                    <a href='https://www.hedinghamcastle.co.uk/' target='_blank' rel="noreferrer" className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />www.hedinghamcastle.co.uk</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 '>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <h3>
                                                    <span>
                                                        Showcasing A Destination
                                                    </span>
                                                </h3>
                                                <h6 className='overview'>
                                                    Overview</h6>
                                                <p className='para-txt'>Hedingham already boasted of a striking website when it came to us for a redesign. We collaborated with Hedingham’s graphic design team to land on an art direction and build a seamless transformation from the existing website into an eye-catching and elegant finished product. We worked closely with the client to understand their goals and visions for the redesign.
                                                </p>
                                                <h6 className='overview history'>
                                                    Inspired by history
                                                </h6>
                                                <p className='para-txt'>The design of the new website had a dual approach to graphics and functionality. We used intricate illustrations inspired by the stunning setting and used it to embellish the vivid photographs of the castle. A combination of old and new images were used to emphasize the unrelenting classic beauty of the architecture and the grounds.
                                                </p>
                                            </div>
                                            <div className='col-md-5  work-we-did'>
                                                <h6>WORK WE DID</h6>
                                                <ul>
                                                    <li>WEBSITE REDESIGN</li>
                                                    <li>CONTENT STRATEGY</li>
                                                    <li>TEMPLATE DEVELOPMENT</li>
                                                    <li>BRANDING & IDENTITY</li>
                                                    <li>USER EXPERIENCE</li>
                                                    <li>DIGITAL STRATEGY</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div class="video-wrapper" data-reactid="158" data-aos="fade-up" data-aos-delay="5">

                                                <div class="mobile-image" data-reactid="163"><img src={hedighamLaptopScaled} title='hedighamLaptopScaled' alt='hedighamLaptopScaled' /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={headinghamTitle} alt='headinghamTitle' title='headinghamTitle'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={livingStandardFullwidth} alt='livingStandardFullwidth' title='livingStandardFullwidth'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={hedinghamCastleBanner} alt='hedinghamCastleBanner' title='hedinghamCastleBanner'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='mobileLabour' data-aos="fade-up" data-aos-delay="5">
                    <img src={hedinghamMobileScaled} alt='hedinghamMobileScaled' title='hedinghamMobileScaled'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}

                {/* ------------------ */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>A BRAND NEW IDENTITY</h6>
                            <h3>A Bigger Success</h3>
                            <p>The final website offered visitors a cinematic feel with stunning images and illustrated accents. Hedingham Castle saw an increase in website hits and reduction in bounce rates that have translated in an improvement in the booking and enquiry. We continue to work with Hedingham on an ongoing basis to help maintain the site and continue optimizing and AB testing the site for user experience and conversions.
                            </p>
                            <ul>
                                <li>One phase website launch</li>
                                <li>Splendid graphic elements</li>
                                <li>Booking events</li>
                                <li>CRM integration</li>
                            </ul>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={hedinghamHomepage} alt='hedinghamHomepage' title='hedinghamHomepage'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* BEGINNING START  */}
                <div className='container' id='beginning' data-aos="fade-up" data-aos-delay="5">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className='content-left'>
                                <h6>SPLENDID VISUALS</h6>
                                <h3>Storytelling through pictures</h3>
                                <p>The website featured a striking use of images to add depth to the storytelling. The development team was tasked with creating an unravelling effect for the content as the visitors scrolled through the site. We built the entire structure of the website in such a way that it emphasizes the interactivity and engagement with the brand. Hedingham was keen on having a technically sorted content system with a short learning curve to enable easy use for management and staff. We decided on WordPress as the platform of choice and integrated it with a ticketing system, customer support software and an events calendar.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6'>
                            <img src={HedinghamImg} alt='HedinghamImg' title='HedinghamImg'></img>
                        </div>
                    </div>
                </div>
                {/* BEGINNING END  */}
                {/* ------------------------/ */}
                {/* TENT PATTERN START */}
                <div className='tent-pattern' data-aos="fade-up" data-aos-delay="5">
                    <img src={Hedingham5} alt='Hedingham5' title='Hedingham5'></img>
                </div>
                {/* TENT PATTERN END */}
                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='replica'>
                    <img src={Hedingham2} alt='Hedingham2' title='Hedingham2'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                {/* TESTIMONIAL START */}
                <div className='testimonial' data-aos="fade-up" data-aos-delay="5">
                    <h2>“ We are grateful to LeapWide for their creative insights and expertise on our new website. They have a keen eye for design and complete understanding of what works. Their awareness and resulting planning on how to unite our goals with the visions we had for our relaunch has proven to be an invaluable asset for our project.”</h2>
                    <p className='testimonial-source'>–Ashbury Smith, Castle Hedingham</p>
                </div>
                {/* TESTIMONIAL END */}
            </div>
        </>
    )
}

export default HedinghamCastle
