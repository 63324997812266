import React from 'react';
import { Helmet } from "react-helmet";
import EconomicSecurityProject1 from "../../Assests/img/EconomicSecurityProject/EconomicSecurityProject1.webp";
import EconomicSecurityProject2 from "../../Assests/img/EconomicSecurityProject/EconomicSecurityProject2.webp";
import EconomicSecurityProject3 from "../../Assests/img/EconomicSecurityProject/EconomicSecurityProject3.webp";
import EconomicSecurityProject4 from "../../Assests/img/EconomicSecurityProject/EconomicSecurityProject4.webp";
import EconomicSecurityProjectMockup from "../../Assests/img/EconomicSecurityProject/EconomicSecurityProjectMockup.webp";
import EconomicSecurityProject5 from "../../Assests/img/EconomicSecurityProject/EconomicSecurityProject5.webp";
import EconomicSecurityProjectTxt from "../../Assests/img/EconomicSecurityProject/EconomicSecurityProjectTxt.webp";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const EconomicSecurityProject = () => {
    return (
        <>
            <Helmet>
                <title>Economic Advocacy Website | Turnkey Design & Development | LeapWide</title>
                <meta name="description" content="See how Leapwide developed a comprehensive website for the Economic Security Project, designed to support economic advocacy with strategic web solutions." />
            </Helmet>
            <div className='top-spacing EconomicSecurityProject container-fluid p-0' id='hedingham-castle'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={EconomicSecurityProject1} alt='EconomicSecurityProject1' title='EconomicSecurityProject1'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h3 class="border-head">
                                    <span>Economic Security Project</span>
                                </h3>
                                <p className='para-txt'>The Economic Security Project champions policies that create economic opportunity for all Americans. Our work includes grantmaking, advocacy, research, and strategic partnerships to transform bold ideas into tangible results.</p>
                            </div>
                        </div>
                    </div>
                    <a href='https://economicsecurityproject.org/' target='_blank' rel="noreferrer" className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />economicsecurityproject.org</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-11 '>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <h3>
                                                <span>Bringing people together around economic justice.
                                                </span>
                                            </h3>
                                            <h6 className='overview'>
                                                Overview</h6>
                                            <p className='para-txt'>The Economic Security Project is a catalyst for change, advancing bold ideas like Guaranteed Income to create an economy that works for everyone. We believe in a future where financial stability is a right, not a privilege. Beyond traditional think tanks or advocacy groups, we bridge the gap between grassroots activism and policymaking. By supporting innovators, conducting research, and building coalitions, we turn visionary concepts into reality.
                                            </p>
                                            <h6 className='overview history'>
                                                Our Partnership
                                            </h6>
                                            <p className='para-txt'>To align their inspiring mission with a powerful visual identity, we collaborated with the Economic Security Project to create a new brand and digital experience for both their c3 and c4 organizations. Our goal was to develop a brand that resonated with diverse audiences, from community leaders to policymakers.
                                            </p>
                                            <h6 className='overview history'>
                                                The Challenge
                                            </h6>
                                            <p className='para-txt'>The Economic Security Project had outgrown its initial brand. They sought a fresh, dynamic identity to match their ambitious goals. With a growing audience and expanding initiatives, they needed a digital platform that effectively communicated their impact and engaged supporters.
                                            </p>
                                        </div>
                                        <div className='col-md-5  work-we-did'>
                                            <h6>WORK WE DID</h6>
                                            <ul>
                                                <li>UX + VISUAL DESIGN</li>
                                                <li>FRONT END DEVELOPMENT</li>
                                                <li>BACK END DEVELOPMENT</li>
                                                <li>BRANDING</li>
                                                <li>CONTENT STRATEGY</li>
                                                <li>INFORMATION ARCHITECTURE</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='container-fluid'>
                                    <div className='row'>
                                        <div class="video-wrapper" data-reactid="158" data-aos="fade-up" data-aos-delay="5">
                                            <div class="mobile-image" data-reactid="163"><img src={AccountableUs2} title='AccountableUs2' alt='AccountableUs2' /></div>
                                        </div>
                                    </div>
                                </div> */}
                            <div className='col-md-1 rotate-img'>
                                <img src={EconomicSecurityProjectTxt} alt='EconomicSecurityProjectTxt' title='EconomicSecurityProjectTxt'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}
                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={EconomicSecurityProject2} alt='EconomicSecurityProject2' title='EconomicSecurityProject2'></img>
                </div>
                {/* COLOR LINE END */}
                {/* ------------------------ */}
                {/* PERSONALIZATION START */}
                <div className='container Personalization' data-aos='fade-up' data-aos-delay='10'>
                    <div className='row'>
                        <h3>Igniting Change</h3>
                        <p>
                            Inspired by the power of a single spark, we crafted distinct visual identities for the Economic Security Project's c3 and c4 organizations. The c3 logo represents an idea expanding outward to create community impact, while the c4 logo, a counterpoint within the c3, symbolizes the grassroots energy fueling the movement. These visual concepts are brought to life through websites that showcase the ESP's transformative work, demonstrating how policy and grassroots activism intersect to create lasting change.
                        </p>
                    </div>
                </div>
                {/* PERSONALIZATION END */}
                {/* ----------------------------- */}
                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={EconomicSecurityProject3} alt='EconomicSecurityProject3' title='EconomicSecurityProject3'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            {/* <h6>A captivating new site</h6> */}
                            <h3>A Visual Narrative of Economic Change</h3>
                            <p>The Economic Security Project's c3 and c4 websites collectively weave a compelling narrative of how bold ideas can transform lives. The c3 site spotlights the organization's policy initiatives, presenting a strong case for each focus area and showcasing their tangible impact. The c4 site complements this by demonstrating how these ideas resonate with the public through polling data and campaign highlights. Both platforms utilize a distinctive editorial collage style to visually enrich the content and bring the organization's mission to life.
                            </p>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={EconomicSecurityProjectMockup} alt='EconomicSecurityProjectMockup' title='EconomicSecurityProjectMockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* ------------------------/ */}
                {/* TENT PATTERN START */}
                <div className='tent-pattern' data-aos="fade-up" data-aos-delay="5">
                    <img src={EconomicSecurityProject4} alt='EconomicSecurityProject4' title='EconomicSecurityProject4'></img>
                </div>
                {/* TENT PATTERN END */}
                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='replica padding-bottom'>
                    <img src={EconomicSecurityProject5} alt='EconomicSecurityProject5' title='EconomicSecurityProject5'></img>
                </div>
                {/* REPLICA END */}

            </div>
        </>
    )
}

export default EconomicSecurityProject
