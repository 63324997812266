import React from 'react';
import { Helmet } from "react-helmet";
import JosephAllenBooneText from "../../Assests/img/josephAllenBoone/JosephAllenBooneText.webp";
import JosephAllenBoone1 from "../../Assests/img/josephAllenBoone/JosephAllenBoone1.webp";
import JosephAllenBoone2 from "../../Assests/img/josephAllenBoone/JosephAllenBoone2.webp";
import JosephAllenBoone3 from "../../Assests/img/josephAllenBoone/JosephAllenBoone3.webp";
import livingStandardFullwidth from "../../Assests/img/living-standard-fullwidth-color.webp";
import JosephAllenBoone4 from "../../Assests/img/josephAllenBoone/JosephAllenBoone4.webp";
import JosephAllenBoone6 from "../../Assests/img/josephAllenBoone/JosephAllenBoone6.webp";
import JosephAllenBooneMockup from "../../Assests/img/josephAllenBoone/JosephAllenBooneMockup.webp"
import JosepAllenBoone from "../../Assests/img/josephAllenBoone/Josep AllenBoone.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const JosephAllenBoone = () => {
    return (
        <>
            <Helmet>
                <title>Author Website Design | Writer Website Design  | LeapWide</title>
                <meta name="description" content="See how Leapwide developed an elegant and scholarly website for writer Joseph Allen Boone, providing a sophisticated online platform for his literary and academic work." />
            </Helmet>
            <div className='top-spacing container-fluid p-0 joseph-allen-boone' id='hedingham-castle'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={JosepAllenBoone} alt='JosepAllenBoone' title='JosepAllenBoone'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h3 class="border-head">
                                    <span>Joseph Allen Boone</span>
                                </h3>
                                <p className='para-txt'>Joseph Allen Boone is a lifelong storyteller. From penning illustrated mysteries as a child to crafting complex narratives in his youth, Boone's literary journey has been a constant evolution.</p>
                            </div>
                        </div>
                    </div>
                    <a href='https://josephallenboone.com/' target='_blank' rel="noreferrer" className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />www.josephallenboone.com</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 '>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <h3>
                                                    <span>
                                                        Bringing Words to Life
                                                    </span>
                                                </h3>
                                                <h6 className='overview'>  Goal</h6>
                                                <p className='para-txt'>We partnered with renowned author Joseph Allen Boone to create a digital platform that mirrored the depth and richness of his literary work. Our goal was to build a website that not only showcased his books but also engaged readers and fostered a vibrant online community.
                                                </p>
                                                <h6 className='overview history'>
                                                    Visually Appealing & Rich in Content
                                                </h6>
                                                <p className='para-txt'>From the outset, our idea was to develop a website that captures the essence of Joseph Allen Boone’s work—rich in content, visually appealing, and easy to navigate. This design should not only complement the intellectual depth of Boone’s writings but also ensure a user-friendly experience. The website’s structure to be intuitive, allowing visitors to seamlessly explore Boone’s publications, projects, and academic contributions.
                                                </p>
                                            </div>
                                            <div className='col-md-5  work-we-did'>
                                                <h6>WORK WE DID</h6>
                                                <ul>
                                                    <li>WEBSITE DESIGN</li>
                                                    <li>INFORMATION ARCHITECTURE</li>
                                                    <li>VISUAL BRANDING</li>
                                                    <li>PROJECT MANAGEMENT</li>
                                                    <li>FRONT END DEVELOPMENT</li>
                                                    <li>BACK END DEVELOPMENT</li>
                                                    <li>WEB ANALYTICS</li>
                                                    <li>MAINTENANCE & SUPPORT (CONTINUING)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div class="video-wrapper" data-reactid="158" data-aos="fade-up" data-aos-delay="5">
                                                <div class="mobile-image" data-reactid="163">
                                                    <img src={JosephAllenBoone1} title='JosephAllenBoone1' alt='JosephAllenBoone1' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={JosephAllenBooneText} alt='JosephAllenBooneText' title='JosephAllenBooneText'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={livingStandardFullwidth} alt='livingStandardFullwidth' title='livingStandardFullwidth'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={JosephAllenBoone2} alt='JosephAllenBoone2' title='JosephAllenBoone2'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='mobileLabour' data-aos="fade-up" data-aos-delay="5">
                    <img src={JosephAllenBoone3} alt='JosephAllenBoone3' title='JosephAllenBoone3'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}

                {/* ------------------ */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>DISTINCTIVE VISUAL IDENTITY</h6>
                            <h3>A Digital Tapestry</h3>
                            <p>The website development process was a collaborative journey. We began by defining the website's structure and navigation through meticulous information architecture. This laid the groundwork for a seamless user experience. Simultaneously, our design team crafted a distinctive visual identity that reflected the author's unique style and literary voice.
                                <br></br><br></br>
                                The result is a polished, professional website that not only showcases Joseph Allen Boone’s contributions to literature and academia but also provides a platform for ongoing engagement with his readers and colleagues.
                            </p>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={JosephAllenBooneMockup} alt='JosephAllenBooneMockup' title='JosephAllenBooneMockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* BEGINNING START  */}
                <div className='container' id='beginning' data-aos="fade-up" data-aos-delay="5">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className='content-left'>
                                <h6>CONTINUE TO BUILD</h6>
                                <h3>Technical Excellence and Ongoing Support</h3>
                                <p>Our development team brought the design vision to life through skillful front-end and back-end development. The website was built on a robust platform, ensuring optimal performance and scalability. To measure website performance and user behavior, we integrated web analytics tools. Our commitment to the project extends beyond launch, with ongoing maintenance and support to ensure the website continues to thrive.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6'>
                            <img src={JosephAllenBoone4} alt='JosephAllenBoone4' title='JosephAllenBoone4'></img>

                        </div>
                    </div>
                </div>
                {/* BEGINNING END  */}

                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='replica'>
                    <img src={JosephAllenBoone6} alt='JosephAllenBoone6' title='JosephAllenBoone6'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                {/* TESTIMONIAL START */}
                <div className='testimonial' data-aos="fade-up" data-aos-delay="5">
                    <h2>“It was an absolute pleasure to see Leapwide build my website. Their team's dedication to understanding my vision and translating it into a captivating digital experience is commendable. The website is not just a platform for my books; it's a reflection of my literary world.”</h2>
                    <p className='testimonial-source'>–Joseph Allen Boone, Author</p>
                </div>
                {/* TESTIMONIAL END */}
            </div>
        </>
    )
}

export default JosephAllenBoone
