import React from 'react';
import { Helmet } from "react-helmet";
import LifeupCorporateWellnessLine from "../../Assests/img/LifeUpCorporateWellness/LifeupCorporateWellnessLine.webp";
import lifeupcorporatewellness1 from "../../Assests/img/LifeUpCorporateWellness/lifeupCorporateWellness1.webp";
import lifeupcorporatewellness2 from "../../Assests/img/LifeUpCorporateWellness/lifeupCorporateWellness2.webp";
import livingStandardFullwidth from "../../Assests/img/living-standard-fullwidth-color.webp";
import lifeupcorporatewellness3 from "../../Assests/img/LifeUpCorporateWellness/lifeupcorporatewellness3.webp";
import lifeupcorporatewellnessMockup from "../../Assests/img/LifeUpCorporateWellness/LifeUpCorporateWellnessMockup.webp";
import lifeupCorporateWellness4 from "../../Assests/img/LifeUpCorporateWellness/lifeupCorporateWellness4.webp";
import lifeupCorporateWellness5 from "../../Assests/img/LifeUpCorporateWellness/lifeupCorporateWellness5.webp";
import lifeupcorporatewellness from "../../Assests/img/LifeUpCorporateWellness/LifeupCorporateWellness.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const LifeupCorporateWellness = () => {
    return (
        <>
            <Helmet>
                <title>Corporate Wellness Website Design | Custom Website Dev | LeapWide</title>
                <meta name="description" content="Leapwide created an engaging and functional website for LifeUp Corporate Wellness, focusing on wellness program promotion through effective web design." />
            </Helmet>
            <div className='top-spacing container-fluid p-0' id='hedingham-castle'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={lifeupcorporatewellness} alt='lifeupcorporatewellness' title='lifeupcorporatewellness'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h3 class="border-head">
                                    <span>Lifeup Corporate Wellness</span>
                                </h3>
                                <p className='para-txt'>LifeUp Corporate Wellness is redefining workplace health. They offer innovative programs that empower employees to achieve optimal well-being. Their holistic approach focuses on physical, mental, and emotional health, fostering thriving workplace cultures.
                                </p>
                            </div>
                        </div>
                    </div>
                    <a href='https://lifeupcorporatewellness.com/' target='_blank' rel="noreferrer" className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />www.lifeupcorporatewellness.com</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 '>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <h3>
                                                    <span>
                                                        Revitalizing the LifeUp Brand
                                                    </span>
                                                </h3>
                                                <h6 className='overview'> Need </h6>
                                                <p className='para-txt'>LifeUp Corporate Wellness needed a website that could clearly communicate their expertise in designing customized wellness programs for busy professionals. The goal was to create a platform that was both visually compelling and easy to navigate, while also being optimized for search engines to attract and convert corporate clients.
                                                </p>
                                                <h6 className='overview history'>
                                                    Our Focus
                                                </h6>
                                                <p className='para-txt'>Our collaboration with LifeUp was a journey to redefine their brand identity and digital presence. By combining strategic thinking, design expertise, and technical prowess, we created a holistic solution that aligned with LifeUp's mission.
                                                </p>
                                            </div>
                                            <div className='col-md-5  work-we-did'>
                                                <h6>WHAT WE DID</h6>
                                                <ul>
                                                    <li>PROJECT MANAGEMENT </li>
                                                    <li>LOGO</li>
                                                    <li>CONTENT STRATEGY </li>
                                                    <li>FRONT END DEVELOPMENT</li>
                                                    <li>BACK END DEVELOPMENT</li>
                                                    <li>UI+UX DESIGN</li>
                                                    <li>BRANDING & IDENTITY</li>
                                                    <li>BRAND STRATEGY</li>
                                                    <li>SEO</li>
                                                    <li>WEB ANALYTICS</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div class="video-wrapper" data-reactid="158" data-aos="fade-up" data-aos-delay="5">
                                                <div class="mobile-image" data-reactid="163">
                                                    <img src={lifeupcorporatewellness1} title='lifeupcorporatewellness1' alt='lifeupcorporatewellness1' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={LifeupCorporateWellnessLine} alt='LifeupCorporateWellnessLine' title='LifeupCorporateWellnessLine'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={livingStandardFullwidth} alt='livingStandardFullwidth' title='livingStandardFullwidth'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={lifeupcorporatewellness2} alt='lifeupcorporatewellness2' title='lifeupcorporatewellness2'></img>
                </div>
                {/* MOBILE LABOUR END */}

                {/* ------------------ */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch liferelaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>BRAND NEW IDENTITY</h6>
                            <h3>Brand Evolution and Digital Foundation</h3>
                            <p>The project encompassed a comprehensive range of services to establish a strong brand identity and a robust digital platform. We began by crafting a distinctive logo that captured LifeUp's essence. Our brand strategy defined the core values, target audience, and messaging. The visual identity, including art direction, brought the brand to life across all touchpoints. To ensure seamless user experience, we conducted in-depth user research and information architecture, forming the foundation for UI/UX design.
                            </p>
                            <ul>
                                <li>One phase website launch</li>
                                <li>Splendid graphic elements </li>
                                <li>SEO Rankings</li>
                                <li>Blogs </li>
                            </ul>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={lifeupcorporatewellnessMockup} alt='lifeupcorporatewellnessMockup' title='lifeupcorporatewellnessMockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* ------------------- */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={lifeupcorporatewellness3} alt='lifeupcorporatewellness3' title='lifeupcorporatewellness2'></img>
                </div>
                {/* ------------------ */}
                {/* BEGINNING START  */}
                <div className='container' id='beginning' data-aos="fade-up" data-aos-delay="5">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className='content-left'>
                                <h6>ATTRACTING NEW CLIENTS</h6>
                                <h3>Building a High-Performance Platform</h3>
                                <p>Our development team translated the design vision into a fully functional website. Front-end development focused on creating an engaging user interface, while back-end development ensured a robust and scalable platform. Rigorous project management and web analytics implementation guaranteed timely delivery and performance optimization.
                                    <br></br><br></br>
                                    The LifeUp Corporate Wellness website now serves as a powerful digital platform, effectively showcasing their services and expertise. With an optimized user experience and strong SEO foundation, LifeUp is well-positioned to attract and convert corporate clients, helping them to enhance employee well-being and productivity.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6'>
                            <img src={lifeupCorporateWellness4} alt='lifeupCorporateWellness4' title='lifeupCorporateWellness4'></img>

                        </div>
                    </div>
                </div>
                {/* BEGINNING END  */}
                {/* ---------------------------- */}

                {/* REPLICA START */}
                <div className='replica'>
                    <img src={lifeupCorporateWellness5} alt='lifeupCorporateWellness5' title='lifeupCorporateWellness5'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                {/* TESTIMONIAL START */}
                <div className='testimonial' data-aos="fade-up" data-aos-delay="5">
                    <h2>“Working with LeapWide was a transformative experience. Their team's deep understanding of our business and their ability to translate our vision into a captivating digital experience exceeded our expectations. The new website has not only elevated our brand but also empowered us to connect with our audience in a meaningful way.”</h2>
                    <p className='testimonial-source'>–Derek Opperman, CEO, LifeUp Corporate Wellness</p>
                </div>
                {/* TESTIMONIAL END */}
            </div>
        </>
    )
}

export default LifeupCorporateWellness
