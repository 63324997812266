import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import logo from "../../Assests/img/LeapWide-logo.png";
import logo1 from "../../Assests/img/logo.webp";
import leapwideteam from "../../Assests/img/LeapWideTeam.webp";
import WhiteLogo from "../../Assests/img/white-logo.webp";
import './Navbar.css';


const Navbar = () => {
    const [sideNavWidth, setSideNavWidth] = useState('0');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const openNav = () => {
        setSideNavWidth('100%');
    };

    const closeNav = () => {
        setSideNavWidth('0');
    };
    return (
        <>
            <div className='container-fluid'>
                <navbar className={scrolled ? 'scrolled fixed-top' : 'fixed-top'} >
                    <div className='logo'>
                        <a href='/' className="top-nav"><img src={logo} alt='logo' title='logo' loading='lazy'></img></a>
                        <a href='/' className='fixed-nav'><img src={WhiteLogo} alt='logo' title='logo' loading='lazy'></img></a>
                    </div>
                    <div className='page-links'>
                        <div className='navLinks'>
                            <li><Link to="/our-work">Our work</Link></li>
                            <li><Link to="/hireus">hire us</Link></li>
                        </div>
                        <li>
                            <a href=''>
                                <div class="hamburger" onClick={openNav}>
                                    <div class="menu-icon">
                                        <input class="menu-icon__cheeckbox" type="checkbox" />
                                        <div>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </div>
                    <div className="sidenav" style={{ width: sideNavWidth }}>
                        <div className='container-fluid sidebar-inner'>
                            <div className='row'>
                                <div className='col-md-6 white-bg'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-3 side-logo'>
                                                <a href='/'><img src={logo1} alt="sidebarlogo" title='sidebarlogo'></img></a></div>
                                            <div className='col-md-9  white-side-txt'>
                                                <h3><a href='/about'>We at LeapWide think of ourselves as cool in code,best in design and great at consulting.</a></h3>
                                                <a href='/about-us'><img src={leapwideteam} alt='leapwideteam' title='leapwideteam'></img></a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 imgBig'>
                                    <div className='bgimg sidebarimg'>
                                        <div className='mobile-show'>
                                            <a href='/' className='mobile-white-logo'><img src={WhiteLogo} alt='WhiteLogo' title='WhiteLogo'></img></a></div>

                                        <a  className="closebtn" onClick={closeNav}>&times;</a>
                                        <span className='right-link'>
                                            <a href="/" class="from-right">Home</a>
                                            <a href="/our-work" class="from-right">Work</a>
                                            <a href="/about" class="from-right">About</a>
                                            <a href="/hire-us" class="from-right">HIRE US</a>
                                            <a href="/contact-us" class="from-right">Contact</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </navbar>
            </div>
            <Outlet />
        </>
    );
};

export default Navbar;

