import React from 'react';
import { Helmet } from "react-helmet";
import ReformAllianceImg from '../../Assests/img/ReformAlliance/ReformAlliance.webp';
import REFORMAllianceLine from '../../Assests/img/ReformAlliance/REFORMAllianceLine.webp';
import reformallianceMockup from "../../Assests/img/ReformAlliance/reformalliance-mockup.webp"
import reformalliance2 from "../../Assests/img/ReformAlliance/reformalliance2.webp";
import reformalliance3 from "../../Assests/img/ReformAlliance/reformalliance3.webp";
import reformalliance4 from "../../Assests/img/ReformAlliance/reformalliance4.webp";
import reformalliance5 from "../../Assests/img/ReformAlliance/reformalliance5.webp";
import reformalliance6 from "../../Assests/img/ReformAlliance/reformalliance6.webp";
import reformalliance11 from "../../Assests/img/ReformAlliance/reformalliance11.webp";
import reformalliance12 from "../../Assests/img/ReformAlliance/reformalliance12.webp";
import reformalliance8 from "../../Assests/img/ReformAlliance/reformalliance8.webp";
import reformalliance9 from "../../Assests/img/ReformAlliance/reformalliance9.webp";
import reformalliance10 from "../../Assests/img/ReformAlliance/reformalliance10.webp";
import { LiaLongArrowAltRightSolid } from 'react-icons/lia';

const ReformAlliance = () => {
    return (
        <>
            <Helmet>
                <title>Advocacy Website Design | Software Solution Development | LeapWide</title>
                <meta name="description" content="Leapwide developed an impactful and user-centric website for Reform Alliance, enhancing their advocacy efforts with strategic design and development." />
            </Helmet>
            <div className='top-spacing container-fluid p-0' id='living-standard'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={ReformAllianceImg} alt='ReformAllianceImg' title='ReformAllianceImg' />
                        </div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h3 className='border-head'>
                                    <span>REFORM Alliance</span>
                                </h3>
                                <p className='para-txt'>
                                    Reform Alliance is a nonprofit working to overhaul probation, parole, and sentencing laws in the U.S. Through legislative advocacy, Reform Alliance works to reduce the number of people trapped in the criminal justice system due to excessive probation, parole, and sentencing terms.
                                </p>
                            </div>
                        </div>
                    </div>
                    <a href='https://reformalliance.com/' target='_blank' rel="noreferrer" className='for-scale'>
                        <button className='firstbtn firstbtn-2 hover-slide-right'>
                            <span>
                                <LiaLongArrowAltRightSolid />reformalliance.com
                            </span>
                        </button>
                    </a>
                </div>

                {/*MODERNIZING START*/}
                <div className='Modernizing' data-aos='fade-up' data-aos-delay='10'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-lg-7 col-md-12'>
                                                <h3>
                                                    <span>Breaking chains, building brands</span>
                                                </h3>
                                                <p className='para-txt'>
                                                    Born from the unjust incarceration of a music icon, REFORM Alliance ignited a movement to dismantle the oppressive chains of probation and parole. We partnered with them to forge a digital identity that would match the scale of their ambition.
                                                    <br /><br />
                                                    REFORM Alliance launched with a strong foundation, but its initial brand and website lacked the impact necessary to distinguish it as a bold leader in criminal justice reform.
                                                    <br /><br />
                                                    LeapWide was tasked with crafting a digital identity that would amplify REFORM’s mission. We sought to revitalize the brand, allowing it to resonate with impactful human stories across digital platforms. Additionally, we aimed to create a website that would not only captivate audiences but also serve as a powerful tool for recruiting new supporters and driving advocacy efforts.
                                                    <br /><br />
                                                    By crafting a compelling brand narrative and designing a dynamic website, we transformed their story into a powerful tool for inspiring action and mobilizing supporters. Together, we built a platform that not only reflects their commitment to justice but also serves as a catalyst for lasting change.
                                                </p>
                                            </div>
                                            <div className='col-lg-5 col-md-7 work-we-did'>
                                                <h6>WORK WE DID</h6>
                                                <ul>
                                                    <li>Brand Strategy</li>
                                                    <li>Messaging & Content Strategy</li>
                                                    <li>Visual Branding + Art Direction</li>
                                                    <li>Web Design + Development</li>
                                                    <li>CRM Integrations</li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* MIDDLE TEXT START */}
                                        <div className='middle-para-txt'>
                                            <p className='para-txt'>
                                                LeapWide redesigned their digital presence in 2020, developing a new website and brand that reflected the organization's commitment to transforming the criminal justice system.
                                            </p>
                                        </div>
                                        {/* MIDDLE TEXT END */}
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={REFORMAllianceLine} alt='REFORMAllianceLine' title='REFORMAllianceLine' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*MODERNIZING END*/}

                {/* COLOR LINE START */}
                <div className='mobileLabour' data-aos='fade-up' data-aos-delay='10'>
                    <img src={reformalliance2} alt='reformalliance2' title='reformalliance2' />
                </div>
                {/* COLOR LINE END */}

                {/* PERSONALIZATION START */}
                <div className='container Personalization' data-aos='fade-up' data-aos-delay='10'>
                    <div className='row'>
                        <h6 className='bold-brand-txt'>BRAND STRATEGY</h6>
                        <h3>Daring, provocative, human</h3>
                        <p>
                            To capture new audiences and redefine the conversation around probation and parole, REFORM Alliance needed a visual identity that was both audacious and relatable. Our approach was to combine the unexpected with the familiar.
                            <br /><br />
                            A striking fusion of Commercial Classics’ Caslon Doric and Klim Type Foundry’s Tiempos, paired with a vibrant neon yellow, black, and cream palette, created a visual language that is as bold as the organization’s mission. Yet, the intimate photography at the heart of the brand ensured a human connection, making the issue personal and relatable.

                        </p>
                    </div>
                </div>
                {/* PERSONALIZATION END */}

                {/* COLOR LINE START */}
                <div className='mobileLabour' data-aos='fade-up' data-aos-delay='10'>
                    <img src={reformalliance3} alt='reformalliance3' title='reformalliance3' />
                </div>
                {/* COLOR LINE END */}
                {/* -------------------------- */}
                {/* COLOR LINE START */}
                <div className='mobileLabour' data-aos='fade-up' data-aos-delay='10'>
                    <img src={reformalliance4} alt='reformalliance4' title='reformalliance4' />
                </div>
                {/* COLOR LINE END */}
                {/* -------------------------- */}
                {/* COLOR LINE START */}
                <div className='mobileLabour' data-aos='fade-up' data-aos-delay='10'>
                    <img src={reformalliance5} alt='reformalliance5' title='reformalliance5' />
                </div>
                {/* COLOR LINE END */}
                {/* -------------------------- */}
                {/* RELAUNCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>WEBSITE</h6>
                            <h3>A human story, digitally told</h3>
                            <p>
                                Our homepage design was a powerful blend of human narrative, clear calls to action, and striking visuals. By prioritizing storytelling, we created an immersive experience that connects with visitors on an emotional level.

                                <br /><br />
                                To bring this vision to life, our development team crafted a seamless scrolling journey using HTML, CSS, JavaScript, and PHP. This technical foundation ensured the website not only looked stunning but also performed flawlessly across devices.
                            </p>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={reformallianceMockup} alt='reformallianceMockup' title='reformallianceMockup' />
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUNCH END */}

                {/* REPLICA START */}
                <div className='replica'>
                    <img src={reformalliance6} alt='reformalliance6' title='reformalliance6' />
                </div>
                {/* REPLICA END */}

                {/* PERSONALIZATION START */}
                <div className='container Personalization' data-aos='fade-up' data-aos-delay='10'>
                    <div className='row'>
                        <h6 className='bold-brand-txt'>MESSAGING & CONTENT STRATEGY</h6>
                        <h3>Building a community of change</h3>
                        <p>
                            Our copywriting extended the brand's visual identity, crafting a compelling narrative that resonated with REFORM's core values. By developing powerful value statements, we created a sense of belonging for supporters, positioning them as integral members of a growing community. This approach transformed the website into a dynamic platform for recruiting, engaging, and mobilizing advocates.

                        </p>
                    </div>
                </div>
                {/* PERSONALIZATION END */}

                {/* REPLICA START */}
                <div className='replica'>
                    <img src={reformalliance11} alt='reformalliance11' title='reformalliance11' />
                </div>
                {/* REPLICA END */}

                {/* REPLICA START */}
                <div className='mobileLabour'>
                    <img src={reformalliance12} alt='reformalliance12' title='reformalliance12' />
                </div>
                {/* REPLICA END */}

                {/* REPLICA START */}
                <div className='replica'>
                    <img src={reformalliance8} alt='reformalliance8' title='reformalliance8' />
                </div>
                {/* REPLICA END */}

                {/* PERSONALIZATION START */}
                <div className='container Personalization' data-aos='fade-up' data-aos-delay='10'>
                    <div className='row'>
                        <h6 className='bold-brand-txt'>SOCIAL</h6>
                        <h3>Social Storytelling</h3>
                        <p>
                            Our foundational branding and creative work has empowered REFORM to excel on social media. All images showcased below are the original work of the REFORM team.
                        </p>
                    </div>
                </div>
                {/* PERSONALIZATION END */}

                {/* REPLICA START */}
                <div className='replica'>
                    <img src={reformalliance9} alt='reformalliance9' title='reformalliance9' />
                </div>
                {/* REPLICA END */}

                {/* REPLICA START */}
                <div className='replica'>
                    <img src={reformalliance12} alt='TheCloudMantra12' title='TheCloudMantra12' />
                </div>
                {/* REPLICA END */}

                {/* REPLICA START */}
                <div className='mobileLabour'>
                    <img src={reformalliance10} alt='reformalliance10' title='reformalliance10' />
                </div>
                {/* REPLICA END */}

                {/* PERSONALIZATION START */}
                <div className='container Personalization' data-aos='fade-up' data-aos-delay='10'>
                    <div className='row'>
                        <p>
                            The REFORM Alliance's updated brand and website convey a compelling narrative about the urgent necessity for probation and parole reform in America. It introduces REFORM to individuals who are passionate about transforming criminal justice reform at the state level.
                        </p>
                    </div>
                </div>
                {/* PERSONALIZATION END */}

                {/* TESTIMONIAL START */}
                <div className='testimonial'>
                    <h2>
                        "We couldn't be happier with the outcome of our website redesign. As a startup with big dreams and limited resources, LeapWide was an invaluable partner. Their ability to balance creativity with efficiency is truly exceptional. The results speak volumes about their talent and dedication."
                    </h2>
                    <p className='testimonial-source'>
                        – Kim Rutherford, Senior Director, Creative Campaigns, REFORM Alliance

                    </p>
                </div>
                {/* TESTIMONIAL END */}
            </div>
        </>
    );
};

export default ReformAlliance;

