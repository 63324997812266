import clientthumblabour from "../Assests/img/TheLabourParty/client-thumb-labour.webp";
import CrisisTextLine from "../Assests/img/CrisisTextLine/CrisisTextLine.webp";
import HedinghamCastle from "../Assests/img/HedinghamCastle/hedingham-castle-banner.webp";
import ProChoiceAmerica from "../Assests/img/LivingStandard/Pro-Choice-America.jpg";
import Lifeup1 from "../Assests/img/LifeUpHealth/Lifeup1.webp";
import ReformAlliance from "../Assests/img/ReformAlliance/ReformAlliance.webp";
import cloudServices from "../Assests/img/TheCloudMantra/CloudServices.webp";
import lifeupCorporateWellness1 from "../Assests/img/LifeUpCorporateWellness/lifeupCorporateWellness1.webp";
import lifeupcorporatewellness from "../Assests/img/LifeUpCorporateWellness/LifeupCorporateWellness.webp";
import JosepAllenBoone from "../Assests/img/josephAllenBoone/Josep AllenBoone.webp";
import HarryBoone from "../Assests/img/HarryBoone/HarryBoone.webp";
import CommunityCatalyst from "../Assests/img/CommunityCatalyst/CommunityCatalyst.webp";
import MarchForOurLives from "../Assests/img/MarchForOurLives/MarchForOurLives.webp";
import NowNyc from "../Assests/img/NowNyc/NowNyc.webp";
import AccountableUs from "../Assests/img/AccountableUs/AccountableUs.webp";
import EconomicSecurityProject from "../Assests/img/EconomicSecurityProject/EconomicSecurityProject.webp";
import civilrights from "../Assests/img/CivilRights/civilrights.webp";
export const OurWorkData = [
    {
        img: [clientthumblabour],
        name: "The Labour Party UK",
        desc: "Website Redesign, Content Strategy, UX + Visual Design, Front and Back End Development",
        link: "/the-labour-party",
        class: "image-container left-sided-work",
        alt: "clientthumblabour",
        title: "clientthumblabour"
    },
    {
        img: [ProChoiceAmerica],
        name: "Living Standard",
        desc: "Interactive Storytelling, Digital Branding, UX + Visual Design, CRM Integration",
        link: "/living-standard",
        class: "image-container right-sided-work",
        alt: "ProChoiceAmerica",
        title: "ProChoiceAmerica"
    },
    {
        img: [HedinghamCastle],
        name: "Hedingham Castle",
        desc: "Website Redesign, Digital Strategy, Branding & Identity, Email Marketing",
        link: "/hedingham-castle",
        class: "image-container left-sided-work",
        alt: "ProChoiceAmerica",
        title: "ProChoiceAmerica"
    },
    {
        img: [Lifeup1],
        name: "LifeUp Health Coaching",
        desc: "Website, Branding, Explainer Video, Front and Backend Development",
        link: "/lifeup-health-coaching",
        class: "image-container right-sided-work",
        alt: "Lifeup1",
        title: "Lifeup1"
    },
    {
        img: [cloudServices],
        name: "The Cloud Mantra",
        desc: "Website, Branding, Explainer Video, Front and Backend Development",
        link: "/the-cloud-mantra",
        class: "image-container left-sided-work",
        alt: "cloudServices",
        title: "cloudServices"
    },
    {
        img: [lifeupCorporateWellness1],
        name: "lifeup Corporate Wellness",
        desc: "Website, Branding, Explainer Video, Front and Backend Development",
        link: "/lifeup-corporate-wellness",
        class: "image-container right-sided-work",
        alt: "lifeup Corporate Wellness",
        title: "lifeup Corporate Wellness"
    },
    {
        img: [JosepAllenBoone],
        name: "Joseph Allen Boone",
        desc: "Website, Branding, Explainer Video, Front and Backend Development",
        link: "/joseph-allen-boone",
        class: "image-container left-sided-work",
        alt: "JosepAllenBoone",
        title: "JosepAllenBoone"
    },
    {
        img: [HarryBoone],
        name: "Harry Boone",
        desc: "Website, Branding, Explainer Video, Front and Backend Development",
        link: "/harryboone",
        class: "image-container right-sided-work",
        alt: "HarryBoone",
        title: "HarryBoone"
    },
    {
        img: [ReformAlliance],
        name: "Reform Alliance",
        desc: "Website, Branding, Explainer Video, Front and Backend Development",
        link: "/reform-alliance",
        class: "image-container left-sided-work",
        alt: "ReformAlliance",
        title: "ReformAlliance"
    },
    {
        img: [CrisisTextLine],
        name: "Crisis Text Line",
        desc: "Website, Branding, Explainer Video, Front and Backend Development",
        link: "/Crisis-Text-Line",
        class: "image-container right-sided-work",
        alt: "CrisisTextLine",
        title: "CrisisTextLine"
    },
    {
        img: [CommunityCatalyst],
        name: "Community Catalyst",
        desc: "Website, Branding, Explainer Video, Front and Backend Development",
        link: "/community-catalyst",
        class: "image-container left-sided-work",
        alt: "CommunityCatalyst",
        title: "CommunityCatalyst"
    },
    {
        img: [MarchForOurLives],
        name: "March For Our Lives",
        desc: "Website, Branding, Explainer Video, Front and Backend Development",
        link: "/march-for-our-lives",
        class: "image-container right-sided-work",
        alt: "MarchForOurLives",
        title: "MarchForOurLives"
    },
    {
        img: [NowNyc],
        name: "National Organization for Women NYC",
        desc: "Website, Branding, Explainer Video, Front and Backend Development",
        link: "/now-nyc",
        class: "image-container left-sided-work",
        alt: "NowNyc",
        title: "NowNyc"
    },
    {
        img: [AccountableUs],
        name: "Accountable.US",
        desc: "Shining a Light",
        link: "/accountable-us",
        class: "image-container right-sided-work",
        alt: "AccountableUs",
        title: "AccountableUs"
    },
    {
        img: [EconomicSecurityProject],
        name: "Economic Security Project ",
        desc: "Bringing people together around economic justice",
        link: "/economic-security-project",
        class: "image-container left-sided-work",
        alt: "EconomicSecurityProject",
        title: "EconomicSecurityProject"
    },
    {
        img: [civilrights],
        name: "The Leadership Conference on Civil & Human Rights",
        desc: "Building an America as good as its ideals",
        link: "/the-leadership-conference-on-civil-human-rights",
        class: "image-container right-sided-work",
        alt: "civilrights",
        title: "civilrights"
    },
];