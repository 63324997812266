import React from 'react';
import { Helmet } from "react-helmet";
import AccountableUs3 from "../../Assests/img/AccountableUs/AccountableUs3.webp";
import AccountableUs4 from "../../Assests/img/AccountableUs/AccountableUs4.webp";
import AccountableUs5 from "../../Assests/img/AccountableUs/AccountableUs5.webp";
import AccountableUsMockup from "../../Assests/img/AccountableUs/AccountableUsMockup.webp";
import AccountableUs6 from "../../Assests/img/AccountableUs/AccountableUs6.webp";
import AccountableUs7 from "../../Assests/img/AccountableUs/AccountableUs7.webp";
import AccountableUs2 from "../../Assests/img/AccountableUs/AccountableUs2.webp";
import AccountableUs1 from "../../Assests/img/AccountableUs/AccountableUs1.webp";
import AccountableTxt from "../../Assests/img/AccountableUs/AccountableTxt.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const AccountableUs = () => {
    return (
        <>
            <Helmet>
                <title>Transparency Website Design | Custom Development Solutions | LeapWide</title>
                <meta name="description" content="Leapwide provided a custom website design for Accountable Us, focusing on transparency and user engagement with tailored web development solutions." />
            </Helmet>
            <div className='top-spacing NowNyc container-fluid p-0' id='hedingham-castle'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={AccountableUs1} alt='AccountableUs1' title='AccountableUs1'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h3 class="border-head">
                                    <span>Accountable.US</span>
                                </h3>
                                <p className='para-txt'>Accountable.US hold corporations, special interests, and policymakers accountable to the American people through rigorous investigations.</p>
                            </div>
                        </div>
                    </div>
                    <a href='https://accountable.us/' target='_blank' className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />accountable.us</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-11 '>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <h3>
                                                <span>Empowering the People, Challenging the Powerful

                                                </span>
                                            </h3>
                                            <h6 className='overview'>
                                                Overview</h6>
                                            <p className='para-txt'>Accountable.US is a nonpartisan watchdog organization shining a light on corporations and special interests that hold undue sway in Washington and beyond. Driven by the belief that power should serve the people, Accountable.US conducts rigorous investigations to expose corruption and abuses of power. Their work fosters an economy that benefits all, a healthy democracy, and a sustainable future for generations to come.
                                            </p>
                                            <h6 className='overview history'>
                                                The Challenge
                                            </h6>
                                            <p className='para-txt'>Accountable.US sought a website that reflected their legacy as a leader in the fight against corruption, while simultaneously refreshing their brand to capture the attention of policymakers and funders amidst a crowded pro-democracy landscape. They needed to stand out from the sea of red, white, and blue organizations. The solution: a refined brand identity, a robust digital content strategy, and a modern, efficient website showcasing their key areas of focus: Economy and technology, right-wing corruption, voting rights, energy and the environment.
                                            </p>
                                        </div>
                                        <div className='col-md-5  work-we-did'>
                                            <h6>WORK WE DID</h6>
                                            <ul>
                                                <li>WEBSITE DEVELOPMENT</li>
                                                <li>CONTENT STRATEGY & COPYWRITING</li>
                                                <li>UX/UI DESIGN</li>
                                                <li>BRAND STRATEGY
                                                </li>
                                                <li>VISUAL BRANDING </li>
                                                <li>COLLATERAL</li>
                                                <li>PERSONAS & USER JOURNEYS</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div class="video-wrapper" data-reactid="158" data-aos="fade-up" data-aos-delay="5">
                                            <div class="mobile-image" data-reactid="163"><img src={AccountableUs2} title='AccountableUs2' alt='AccountableUs2' /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1 rotate-img'>
                                <img src={AccountableTxt} alt='AccountableTxt' title='AccountableTxt'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={AccountableUs3} alt='AccountableUs3' title='AccountableUs3'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={AccountableUs4} alt='AccountableUs4' title='AccountableUs4'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={AccountableUs5} alt='AccountableUs5' title='AccountableUs5'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            {/* <h6>A captivating new site</h6> */}
                            <h3>Solution: A bold voice for accountability</h3>
                            <p>LeapWide designed a progressive, hard-hitting brand that positions Accountable.US as a powerful force for good, akin to the "VICE News" of government and corporate watchdogs. Moving away from traditional aesthetics, the refreshed identity features a strong editorial feel and a vibrant color palette to spotlight instances of wrongdoing.
                                <br></br> <br></br>
                                A captivating narrative homepage engages visitors, highlighting their work to preserve democracy and expose corruption. Breaking news and recent successes scroll continuously on a ticker, keeping visitors informed. Built for flexibility, the site allows for effortless content updates and expansion.
                            </p>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={AccountableUsMockup} alt='AccountableUsMockup' title='AccountableUsMockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* ------------------------/ */}
                {/* TENT PATTERN START */}
                <div className='tent-pattern' data-aos="fade-up" data-aos-delay="5">
                    <img src={AccountableUs6} alt='AccountableUs6' title='AccountableUs6'></img>
                </div>
                {/* TENT PATTERN END */}
                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='replica'>
                    <img src={AccountableUs7} alt='AccountableUs7' title='AccountableUs7'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                {/* PERSONALIZATION START */}
                <div className='container Personalization' data-aos='fade-up' data-aos-delay='10'>
                    <div className='row'>
                        <h3>Exposing the Power Brokers</h3>
                        <p>
                            Accountable.US shines a light on corruption, informing Americans and influencing policymakers to create a more just and equitable system.
                        </p>
                    </div>
                </div>
                {/* PERSONALIZATION END */}
                {/* --------------------------------- */}

            </div>
        </>
    )
}

export default AccountableUs
