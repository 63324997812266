import React from 'react';
import { Helmet } from "react-helmet";
import lifeuphealthcoaching from "../../Assests/img/LifeUpHealth/lifeuphealthcoaching.webp";
import lifeupLaptopScaled from "../../Assests/img/LifeUpHealth/lifeupLaptopScaled.webp";
import LifeUpHealthText from "../../Assests/img/LifeUpHealth/LifeUpHealthText.webp";
import lifeupMobileScaled from "../../Assests/img/LifeUpHealth/lifeup-mobile-scaled.webp";
import lifeupFullPage from "../../Assests/img/LifeUpHealth/lifeup-full-page.webp";
import Lifeup4 from "../../Assests/img/LifeUpHealth/Lifeup4.webp";
import Lifeup5 from "../../Assests/img/LifeUpHealth/Lifeup5.webp";
import Lifeup1 from "../../Assests/img/LifeUpHealth/Lifeup1.webp";
import LifeUp3 from "../../Assests/img/LifeUpHealth/Lifeup3.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const LifeupHealthCoaching = () => {
    return (
        <>
            <Helmet>
                <title>Health Coaching Website Design | Small Business Website | LeapWide</title>
                <meta name="description" content="Leapwide designed a professional and accessible website for LifeUp Health Coaching, enhancing their digital presence with expert web development and design." />
            </Helmet>
            <div className='top-spacing container-fluid p-0' id='lifeup-health'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={lifeuphealthcoaching} alt='lifeuphealthcoaching' title='lifeuphealthcoaching'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h3 class="border-head">
                                    <span>LifeUp Health Coaching</span>
                                </h3>
                                <p className='para-txt'>LifeUP Health Coaching is run by Integrative Health Coach, Derek Opperman. The company helps you to achieve health, wellness, and body transformation from a multi-faceted approach & compound your results. LifeUP’s mission is to teach the best health practices that are impactful and applicable to all.</p>
                            </div>
                        </div>
                    </div>
                    <a href='https://lifeuphealthcoaching.com/' target='_blank' rel="noreferrer" className='for-scale'>
                        <button class="firstbtn firstbtn-2 hover-slide-right">
                            <span><LiaLongArrowAltRightSolid />lifeuphealthcoaching</span>
                        </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 '>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <h3>
                                                    <span>
                                                        Reclaim Your Health
                                                    </span>
                                                </h3>
                                                <h6 className='overview'>
                                                    Stay Fit
                                                </h6>
                                                <p className='para-txt'>LifeUP looked to collaborate with a digital agency that understood how important it was to create a powerful online identity to convey the unique work they were doing. LeapWide worked with LifeUP’s team to conceptualize an art direction that provided a smooth transition for their current digital presence.
                                                    <br></br>
                                                    <br></br>
                                                    LifeUP had an impressive vision and needed a novel way to catch the attention it deserved. As a new initiative with little content, we had a clear starting point. How do we create a landing page with the flexibility to grow with the organization using a captivating story that pulls the readers? Following extensive creative brainstorming and user experience testing, we created an interactive page with a vivid flow, to not only draw readers in but compel them to sign up.
                                                </p>
                                            </div>
                                            <div className='col-md-5  work-we-did'>
                                                <h6>WORK WE DID</h6>
                                                <ul>
                                                    <li>UX & UI</li>
                                                    <li>DESIGN & IDEATION</li>
                                                    <li>CONTENT STRATEGY</li>
                                                    <li>BRANDING & IDENTITY</li>
                                                    <li>EXPLAINER VIDEO</li>
                                                    <li>EMAIL MARKETING</li>
                                                    <li>PROJECT MANAGEMENT</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div class="video-wrapper" data-reactid="158" data-aos="fade-up" data-aos-delay="5">

                                                <div class="mobile-image" data-reactid="163"><img src={lifeupLaptopScaled} title='lifeupLaptopScaled' alt='lifeupLaptopScaled' /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={LifeUpHealthText} alt='LifeUpHealthText' title='LifeUpHealthText'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={LifeUp3} alt='LifeUp3' title='LifeUp3'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={lifeupMobileScaled} alt='lifeupMobileScaled' title='lifeupMobileScaled'></img>
                </div>
                {/* MOBILE LABOUR END */}

                {/* ------------------ */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>STAY HEALTHY. STAY FIT.
                            </h6>
                            <h3>Launching a beautiful website</h3>
                            <p>LifeUP’s website was launched in one go. We sought to develop a neat and minimalist visual concept complemented by interactive graphics that emphasized the core of LifeUP’s simple functionality and efficient solution. A combination of aesthetically pleasing illustrations, vivid graphics, and a refreshing color scheme was chosen for the website and the web application. The content placement was designed to maximize conversions and inspire reliability and security for the target audience.
                            </p>
                            <ul>
                                <li>Animated graphic elements</li>
                                <li>Landing page for corporate clients</li>
                                <li>Advanced motion graphics</li>
                                <li>e-commerce enabled</li>
                            </ul>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={lifeupFullPage} alt='lifeupFullPage' title='lifeupFullPage'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* BEGINNING START  */}
                <div className='container' id='beginning' data-aos="fade-up" data-aos-delay="5">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className='content-left'>
                                <h6>BRANDING AND WEBSITE DEVELOPMENT</h6>
                                <h3>Helping them grow</h3>
                                <p>The website featured a striking use of images to add depth to the storytelling. The development team was tasked with creating an unraveling effect for the content as the visitors scrolled through the site. We also helped build a new logo for LifeUP to truly emphasize the interactivity and engagement with the brand and ultimately the awareness for LifeUP’s mission. These immersive touches transferred smoothly to mobile sites after the motion graphics and loading times were carefully scaled back to be fast and seamless.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6'>
                            <img src={Lifeup4} alt='Lifeup4' title='Lifeup4'></img>

                        </div>
                    </div>
                </div>
                {/* BEGINNING END  */}
                {/* ------------------------/ */}
                {/* TENT PATTERN START */}
                <div className='tent-pattern' data-aos="fade-up" data-aos-delay="5">
                    <img src={Lifeup5} alt='Lifeup5' title='Lifeup5'></img>
                </div>
                {/* TENT PATTERN END */}
                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='replica'>
                    <img src={Lifeup1} alt='Lifeup1' title='Lifeup1'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                {/* TESTIMONIAL START */}
                <div className='testimonial' data-aos="fade-up" data-aos-delay="5">
                    <h2>“Working with LeapWide has been a phenomenal experience. Their continued dedication and attention to detail has played a big role in developing a valuable product from the ground up. Their integrity, communication and reliability has remained top notch from the beginning to the end.”</h2>
                    <p className='testimonial-source'>-Derek Opperman (Integrative Health Coach), Founder Director / LifeUP Health Coaching</p>
                </div>
                {/* TESTIMONIAL END */}
            </div>
        </>
    )
}

export default LifeupHealthCoaching;
